import DropdownSelector from 'components/ui-new/dropdown-permission-selector/dropdown-permission-selector';
import { LanguageName, SettingsActions, CheckboxNames } from 'pages/settings/settings.controller';
import { connect } from 'react-redux';
import Checkbox from 'components/checkbox/checkbox';
import { Tooltip } from '@mui/material';
import { InfoIcon } from 'icons/info';
import { AppState } from 'redux/store';
import { AppLanguage } from 'types/common-enums';
import { IdName } from 'types/common-types';
import s from 'pages/settings/settings.module.scss';

type StateProps = {
  language: IdName;
  isDisplayHierarchy: boolean;
  isDisplayRootProduct: boolean;
  isDisplayProductionNote: boolean;
  isDisplayProductVariantPhoto: boolean;
};
type DispatchProps = {
  onChangeLanguage: (value: IdName) => void;
  onChangeDatailsOnTheTaskCard: (checkboxName: CheckboxNames, value: boolean) => void;
  onChangeDisplayVariantPhotoOnTaskCard: (checkboxName: CheckboxNames, value: boolean) => void;
};
type Props = StateProps & DispatchProps;

const options = {
  appLanguage: [
    {
      id: AppLanguage.English,
      name: LanguageName.English,
    },
    {
      id: AppLanguage.Ukrainian,
      name: LanguageName.Ukrainian,
    },
  ],
};

const MobileTabContent = ({
  language,
  isDisplayHierarchy,
  isDisplayRootProduct,
  isDisplayProductionNote,
  isDisplayProductVariantPhoto,
  onChangeLanguage,
  onChangeDatailsOnTheTaskCard,
  onChangeDisplayVariantPhotoOnTaskCard,
}: Props) => {
  return (
    <div className={s.section}>
      <div className={s.row}>
        <b className={s.row_title}>Mobile app default language</b>
        <div className={s.right_col}>
          <DropdownSelector value={language} isFullDropdownWidth options={options.appLanguage} onChange={onChangeLanguage} />
        </div>
      </div>

      <div className={s.row}>
        <b className={s.row_title}>
          Display &quot;Hierarchy&quot; details on the task card
          <Tooltip
            placement="top"
            classes={{ tooltip: s.tooltip }}
            title="By enabling this setting, mobile app users will be able to see the production hierarcy from root production to production, where the task is placed"
          >
            <div className={s.icon_container}>
              <InfoIcon className={s.info_icon} />
            </div>
          </Tooltip>
        </b>
        <Checkbox
          size="medium"
          checked={isDisplayHierarchy}
          onChange={(e) => onChangeDatailsOnTheTaskCard(CheckboxNames.Hierarchy, e.target.checked)}
        />
      </div>

      <div className={s.row}>
        <b className={s.row_title}>
          Display &quot;Root product&quot; details on the task card
          <Tooltip
            placement="top"
            classes={{ tooltip: s.tooltip }}
            title="By enabling this setting, mobile app users will be able to see the details of the root product, if task is placed in the production of the semifinished product"
          >
            <div className={s.icon_container}>
              <InfoIcon className={s.info_icon} />
            </div>
          </Tooltip>
        </b>
        <Checkbox
          size="medium"
          checked={isDisplayRootProduct}
          onChange={(e) => onChangeDatailsOnTheTaskCard(CheckboxNames.RootProduct, e.target.checked)}
        />
      </div>

      <div className={s.row}>
        <b className={s.row_title}>
          Display &quot;Production note&quot; details on the task card
          <Tooltip
            placement="top"
            classes={{ tooltip: s.tooltip }}
            title="By enabling this setting, mobile app users will be able to see the note regarding the production, where the task is placed"
          >
            <div className={s.icon_container}>
              <InfoIcon className={s.info_icon} />
            </div>
          </Tooltip>
        </b>
        <Checkbox
          size="medium"
          checked={isDisplayProductionNote}
          onChange={(e) => onChangeDatailsOnTheTaskCard(CheckboxNames.ProductionNote, e.target.checked)}
        />
      </div>

      <div className={s.row}>
        <b className={s.row_title}>
          Display product variant photo on the task card
          <Tooltip
            placement="top"
            classes={{ tooltip: s.tooltip }}
            title="By enabling this setting, mobile app users will be able to see the photo of product variant (if you added one)"
          >
            <div className={s.icon_container}>
              <InfoIcon className={s.info_icon} />
            </div>
          </Tooltip>
        </b>
        <Checkbox
          size="medium"
          checked={isDisplayProductVariantPhoto}
          onChange={(e) => onChangeDisplayVariantPhotoOnTaskCard(CheckboxNames.ProductVariantPhoto, e.target.checked)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  language: state.settings.language,
  isDisplayHierarchy: state.settings.isDisplayHierarchy,
  isDisplayRootProduct: state.settings.isDisplayRootProduct,
  isDisplayProductionNote: state.settings.isDisplayProductionNote,
  isDisplayProductVariantPhoto: state.settings.isDisplayProductVariantPhoto,
});
const mapDispatchToProps: DispatchProps = {
  onChangeLanguage: SettingsActions.onChangeLanguage,
  onChangeDatailsOnTheTaskCard: SettingsActions.onChangeDatailsOnTheTaskCard,
  onChangeDisplayVariantPhotoOnTaskCard: SettingsActions.onChangeDisplayVariantPhotoOnTaskCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileTabContent);
