import { COLORS } from 'components/manage-tags-modals/constants';
import { notify } from 'notifications';
import { DeleteProductionTagModalActions } from 'pages/production/controllers/delete-production-tag-modal.controller';
import { ManageProductionTagsModalActions } from 'pages/production/controllers/manage-production-tags-modal.controller';
import { GetStateFunction } from 'redux/store';
import { Paths } from 'routes/paths';
import { ProductionWorkflowTagModel } from 'services/production-workflow.model';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { StateController } from 'state-controller';

export type ProductionTagEditorArgs = {
  tag?: ProductionWorkflowTagModel;
  mode: 'create' | 'edit';
};

export type TagEditorState = {
  isOpen: boolean;
  isTagChanged: boolean;
  tag: ProductionWorkflowTagModel;
  mode: ProductionTagEditorArgs['mode'];
};

const defaultState: TagEditorState = {
  isOpen: false,
  mode: 'create',
  isTagChanged: false,
  tag: {
    id: '',
    name: '',
    created_at: '',
    color: COLORS['#FFF2F9'].bgColor,
  },
};

const stateController = new StateController<TagEditorState>('TAG_EDITOR_MODAL', defaultState);

export class ProductionTagEditorActions {
  public static openModal({ tag, mode }: ProductionTagEditorArgs) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: true, tag: tag || defaultState.tag, mode, isTagChanged: false }));
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
    };
  }

  public static handleChangeName(value: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { tag } = getState().production.tagEditorModal;

      const isChanged = value !== tag.name;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tag: {
            ...prev.tag,
            name: value,
          },
          isTagChanged: isChanged,
        })),
      );
    };
  }

  public static handleChangeColor(color: string) {
    return async (dispatch, getState) => {
      const { tag } = getState().production.tagEditorModal;

      const isChanged = color !== tag.color;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tag: {
            ...prev.tag,
            color,
          },
          isTagChanged: isChanged,
        })),
      );
    };
  }

  public static handleDelete(route: Paths) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const { tag } = getState().production.tagEditorModal;

        const deletedTag = await ProductionWorkflowService.deleteProductionTag(tag.id);

        dispatch(ManageProductionTagsModalActions.deleteTag(deletedTag, route));
        dispatch(DeleteProductionTagModalActions.closeModal());
        dispatch(stateController.setState({ isOpen: false }));
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  public static handleSave(mode: 'create' | 'edit', route: Paths) {
    return async (dispatch, getState: GetStateFunction) => {
      const { tag } = getState().production.tagEditorModal;

      if (mode === 'create') {
        const createdTag = await ProductionWorkflowService.createProductionTag(tag);

        dispatch(ManageProductionTagsModalActions.addTag(createdTag));
      } else {
        const updatedTag = await ProductionWorkflowService.updateProductionTag(tag.id, tag);

        dispatch(ManageProductionTagsModalActions.updateTag(updatedTag, route));
      }

      dispatch(stateController.setState({ isOpen: false }));
    };
  }
}

export const productionTagEditorModalReducer = stateController.getReducer();
